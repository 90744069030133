<template>
  <div class="friends">
    <div class="friends__header">
      <h1 class="friends__header_title">Friends of Grottas</h1>
      <span class="friends__header_description">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque condimentum augue felis,
        vitae vulputate nunc efficitur vel. Vivamus at elit sagittis, gravida dui nec, efficiturs
        odio.
      </span>
    </div>

    <div class="friends__body">
      <div v-for="(friend, index) in friends" :key="`friend-${index}`" class="friends__body_item">
        <router-link class="link" :to="{ name: 'ArtistProfile', params: { slug: friend.slug } }">
          <div
            class="photo"
            v-webp:bg="friend.img"
            :style="{
              backgroundImage: `url(${friend.img})`,
            }"
          ></div>
          <span class="name">{{ friend.name }}</span>
        </router-link>
      </div>
    </div>

    <div class="navigation__container">
      <span class="navigation__container_title">Experience: Categories</span>
      <div class="navigation__container_buttons-box">
        <BaseButton
          class="button--white button--oval"
          text="Explore Categories"
          @click.native="goToPage('Collection')"
        />
        <BaseButton
          class="button--outline-white button--oval"
          text="Back to Home"
          @click.native="goToPage('Home')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/buttons/BaseButton.vue";
import { mapMutations } from "vuex";

export default {
  components: { BaseButton },

  data() {
    return {
      friends: [
        {
          slug: "tom-grotta",
          name: "Tom Grotta",
          img: require("@/assets/images/friends/tom-grotta.png"),
        },
        {
          slug: "john-mcqueen",
          name: "John McQueen",
          img: require("@/assets/images/friends/jonh-mcqueen.png"),
        },
        {
          slug: "joyce-and-edgar-anderson",
          name: "Joyce and Edgar Anderson",
          img: require("@/assets/images/friends/joyce-and-edgar-anderson.png"),
        },
        {
          slug: "toshiko-takaezu",
          name: "Toshiko Takaezu",
          img: require("@/assets/images/friends/toshiko-takaezu.png"),
        },
        {
          slug: "norma-minkowitz",
          name: "Norma Minkowitz",
          img: require("@/assets/images/friends/norma-minkowitz.png"),
        },
        {
          slug: "sheila-hicks",
          name: "Sheila Hicks",
          img: require("@/assets/images/friends/sheila-hicks.png"),
        },
        {
          slug: "massimo-vignelli",
          name: "Massimo Vignelli",
          img: require("@/assets/images/friends/massimo-vignelli.png"),
        },
        {
          slug: "mariette-rousseau",
          name: "Mariette Rousseau-Vermette",
          img: require("@/assets/images/friends/mariette-rousseau.png"),
        },
        {
          slug: "jake-di-pietro",
          name: "Jake Di Pietro",
          img: require("@/assets/images/friends/jake-di-pietro.png"),
        },
        {
          slug: "axel-ruessmeyer",
          name: "Axel Ruessmeyer",
          img: require("@/assets/images/friends/axel-ruessmeyer.png"),
        },
        {
          slug: "richarf-meier",
          name: "Richard Meier",
          img: require("@/assets/images/friends/richarf-meier.png"),
        },
        {
          slug: "jack-lenore-larson",
          name: "Jack Lenore Larson",
          img: require("@/assets/images/friends/jack-larson.png"),
        },
      ],
    };
  },

  head: {
    title: function () {
      return {
        inner: "GH - Friends of Grottas",
        separator: " ",
        complement: " ",
      };
    },
    meta: function () {
      return [];
    },
  },

  created() {
    window.scrollTo(0, 0);

    const breadcrumbs = [
      {
        title: "Home",
        to: "/",
      },
      {
        title: "Friends of Grottas",
        to: "/about/friends-of-grottas",
      },
    ];
    this.setBreadcrumbs(breadcrumbs);
  },

  methods: {
    ...mapMutations(["setBreadcrumbs"]),

    goToPage(page) {
      this.$router.push({ name: page });
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  color: $white;
}

.friends {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $lightBlack;
  min-height: 100vh;
  min-width: 100vw;
  font-size: 2.1rem;
  line-height: 3.5rem;
  @media (max-width: $xs) {
    font-size: 1.8rem;
    line-height: 2.8rem;
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15.6rem;
    @media (max-width: $xs) {
      margin-top: 28.3rem;
      padding: 0rem 2.8rem;
    }

    &_title {
      display: inline-block;
      text-align: center;
      font-family: $fontBodoni;
      font-size: 6.2rem;
      line-height: 6.8rem;
      @media (max-width: $xs) {
        font-size: 4rem;
        line-height: 5rem;
      }
    }

    &_description {
      display: inline-block;
      text-align: center;
      margin-top: 4.4rem;
      width: 55%;
      @media (max-width: $lg) {
        width: 80%;
      }
      @media (max-width: $xs) {
        width: 100%;
        margin-top: 6.7rem;
      }
    }
  }

  &__body {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 8.6rem 16.4rem 16.5rem;
    @media (max-width: $lg) {
      padding: 8.6rem 6.4rem 16.5rem;
    }
    @media (max-width: $sm) {
      padding: 6.6rem 2.4rem 16.5rem;
    }
    @media (max-width: $xs) {
      padding: 8rem 2.8rem 15rem;
    }

    &_item {
      padding: 5.3rem 5.5rem 0rem;
      width: 33%;
      @media (max-width: $lg) {
        width: 50%;
      }
      @media (max-width: $sm) {
        padding: 5.3rem 2.5rem 0rem;
      }
      @media (max-width: $xs) {
        display: none;
        padding: 5.3rem 0rem 0rem;
        width: 100%;
      }
      .link {
        display: block;
        text-decoration: none;
      }
      .photo {
        height: 39.3rem;
        width: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        margin-bottom: 1rem;
        cursor: pointer;
        @media (max-width: $xs) {
          height: 32.1rem;
          width: 100%;
        }
      }

      .name {
        font-size: 2.2rem;
        font-family: $fontBodoni;
        cursor: pointer;
      }

      &:nth-child(3n + 2) {
        margin-top: 5.4rem;
        @media (max-width: $lg) {
          margin-top: 0rem;
        }
      }

      @media (max-width: $xs) {
        &:nth-child(-n + 4) {
          display: block;
        }
      }
    }
  }

  .navigation__container {
    display: flex;
    flex-direction: column;
    margin: 7.5rem 0rem 10.4rem;
    padding-left: 30%;
    width: 100%;
    @media (max-width: $sm) {
      padding-left: 20%;
    }
    @media (max-width: $xs) {
      margin: 0rem 0rem 18.9rem;
      padding-left: 2.6rem;
    }

    &_title {
      font-family: $fontBodoni;
      font-size: 3.8rem;
      line-height: 4.8rem;
      margin: 0rem 0rem 5rem 1.2rem;
      @media (max-width: $xs) {
        font-size: 2.5rem;
        line-height: 3.1rem;
        margin: 0rem 0rem 3rem 0rem;
      }
    }

    &_buttons-box {
      display: flex;
      align-items: center;
      @media (max-width: $xs) {
        flex-wrap: wrap;
      }

      &::after {
        content: "";
        background-color: $white;
        height: 1px;
        flex-grow: 100;
        @media (max-width: $xs) {
          margin-top: 16px;
        }
      }

      .button--oval {
        font-family: $fontBodoni;
        margin-left: 12px;
        @media (max-width: $xs) {
          margin-left: 0px;
          padding: 12px 18px;
        }
      }

      @media (max-width: $xs) {
        .button--outline-white {
          margin-top: 17px;
        }
      }
    }
  }
}
</style>
